<template>
  <section id="table-users">
    <b-overlay :show="showUsers" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            IQ Tool Users
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-iq-tool-users" />
          </h4>
          <b-popover target="popover-iq-tool-users" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button-group>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="openRolesTable">
                Roles
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addUserClicked">
                Add user
              </b-button>
            </b-button-group>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="usersTable.currentPage"
          :items="usersTable.items" :fields="usersTable.fields" :sort-by.sync="usersTable.sortBy"
          :sort-desc.sync="usersTable.sortDesc" :sort-direction="usersTable.sortDirection" :filter="usersTable.filter"
          :filter-included-fields="usersTable.filterOn" @row-clicked="usersTableRowClicked">
          <template #cell(role)="data">
            <b-badge :key="data.item.role" variant="light-primary">
              {{ data.item.role_name }}
            </b-badge>
          </template>
          <template #cell(delete)="data">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
              @click="openDeleteUserConfirm(data.item.id)">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="usersTable.currentPage" :total-rows="usersTable.totalRows" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0" @change="handleUsersTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="show-roles-modal" size="xl" title="Roles" cancel-title="Close" cancel-variant="outline-secondary"
        :no-close-on-backdrop="true">
        <b-overlay :show="showRoles" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Roles
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-roles" />
              </h4>
              <b-popover target="popover-roles" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
              <b-card-text class="font-medium-5 mb-0">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                  @click="addRoleClicked">
                  Add role
                </b-button>
              </b-card-text>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="rolesTable.currentPage"
              :items="rolesTable.items" :fields="rolesTable.fields" :sort-by.sync="rolesTable.sortBy"
              :sort-desc.sync="rolesTable.sortDesc" :sort-direction="rolesTable.sortDirection"
              :filter="rolesTable.filter" :filter-included-fields="rolesTable.filterOn"
              @row-clicked="rolesTableRowClicked">
              <template #cell(page_accesses_names)="data">
                <b-badge v-for="(item, index) in data.value" :key="index" variant="light-primary">
                  {{ item.name }}
                </b-badge>
              </template>
              <template #cell(delete)="data">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
                  @click="openDeleteRoleConfirm(data.item.id)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="rolesTable.currentPage" :total-rows="rolesTable.totalRows" first-number
                  last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleRolesTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-modal>
      <b-modal ref="modal-user-submit" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
        centered :title="userID ? 'Update User' : 'Add User'" :no-close-on-backdrop="true" @ok="handleUserFormSubmit"
        @hidden="resetUserFormModal">
        <b-form @submit.prevent>
          <b-row>
            <!-- email -->
            <b-col cols="12">
              <b-form-group label="Email" label-for="email">
                <b-form-input id="email" v-model="email" type="email" :disabled="userID != ''" />
                <b-form-text v-if="userID == ''" class="text-danger">
                  Enter a Valid Email
                </b-form-text>
              </b-form-group>
            </b-col>
            <!-- username -->
            <b-col cols="12">
              <b-form-group label="Username" label-for="usename">
                <b-form-input id="usename" v-model="username" :disabled="userID != ''" />
              </b-form-group>
            </b-col>
            <!-- role -->
            <b-col cols="12">
              <b-form-group label="Role" label-for="role">
                <v-select id="role" v-model="selectedRole" label="name" :options="roles" />
              </b-form-group>
            </b-col>
            <!-- vacation per year -->
            <b-col cols="12">
              <b-form-group label="Vacation per year" label-for="vacation-per-year">
                <b-form-input id="vacation-per-year" v-model="vacationPerYear" type="number" />
              </b-form-group>
            </b-col>
            <!-- sickday per year -->
            <b-col cols="12">
              <b-form-group label="Sickday per year" label-for="sickday-per-year">
                <b-form-input id="sickday-per-year" v-model="sickdayPerYear" type="number" />
              </b-form-group>
            </b-col>
            <!-- password -->
            <b-col v-if="userID == ''" cols="12">
              <b-form-group label="Password" label-for="password">
                <b-input-group class="input-group-merge" :class="validation ? 'is-valid' : 'is-invalid'">
                  <b-form-input id="password" v-model="password" type="password" :state="validation" />
                </b-input-group>
                <b-form-invalid-feedback :state="validation">
                  Your password must be 8-20 characters long.
                </b-form-invalid-feedback>
                <b-form-valid-feedback :state="validation">
                  Looks Good.
                </b-form-valid-feedback>
              </b-form-group>
            </b-col>
            <!-- re-password -->
            <b-col v-if="userID == ''" cols="12">
              <b-form-group label="Re-Password" label-for="re-password">
                <b-input-group class="input-group-merge" :class="validation ? 'is-valid' : 'is-invalid'">
                  <b-form-input id="re-password" v-model="rePassword" type="password" :state="validation" />
                </b-input-group>
                <b-form-invalid-feedback :state="validation">
                  Your password must be 8-20 characters long.
                </b-form-invalid-feedback>
                <b-form-valid-feedback :state="validation">
                  Looks Good.
                </b-form-valid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal ref="modal-role-submit" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
        centered :title="roleID ? 'Update Role' : 'Add Role'" :no-close-on-backdrop="true" @ok="handleRoleFormSubmit"
        @hidden="resetRoleFormModal">
        <b-form @submit.prevent>
          <b-row>
            <!-- role name -->
            <b-col cols="12">
              <b-form-group label="Role name" label-for="role-name">
                <b-form-input id="role-name" v-model="roleName" :disabled="roleID != ''" />
              </b-form-group>
            </b-col>
            <!-- access pages -->
            <b-col cols="12">
              <b-form-group label="Access pages" label-for="access-pages">
                <v-select id="access-pages" v-model="accessPagesSelected" label="name" multiple
                  :options="accessPages" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </b-overlay>
    <b-overlay :show="showEmployees" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Employees of the Company
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-employees" />
          </h4>
          <b-popover target="popover-employees" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-button-group>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="openTeamsTable">
                Teams
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="openContractsTable">
                Contracts
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addEmployeeClicked">
                Add employee
              </b-button>
            </b-button-group>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="employeesTable.currentPage"
          :items="employeesTable.items" :fields="employeesTable.fields" :sort-by.sync="employeesTable.sortBy"
          :sort-desc.sync="employeesTable.sortDesc" :sort-direction="employeesTable.sortDirection"
          :filter="employeesTable.filter" :filter-included-fields="employeesTable.filterOn"
          @row-clicked="employeesTableRowClicked">
          <template #cell(user)="data">
            <b-badge :key="data.item.user" variant="light-primary">
              {{ data.item.user_name }}
            </b-badge>
          </template>
          <template #cell(team)="data">
            <b-badge :key="data.item.team" variant="light-primary">
              {{ data.item.team_name }}
            </b-badge>
          </template>
          <template #cell(contract)="data">
            <b-badge :key="data.item.contract" variant="light-primary">
              {{ data.item.contract_name }}
            </b-badge>
          </template>
          <template #cell(is_freelancer)="data">
            <div v-if="data.item.is_freelancer">
              <feather-icon icon="CheckCircleIcon" />
            </div>
            <div v-else>
              <feather-icon icon="XCircleIcon" />
            </div>
          </template>
          <template #cell(delete)="data">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
              @click="openDeleteEmployeeConfirm(data.item.id)">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="employeesTable.currentPage" :total-rows="employeesTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleEmployeesTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="show-teams-modal" size="xl" title="Teams" cancel-title="Close" cancel-variant="outline-secondary"
        :no-close-on-backdrop="true">
        <b-overlay :show="showTeams" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Teams
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-teams" />
              </h4>
              <b-popover target="popover-teams" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
              <b-card-text class="font-medium-5 mb-0">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                  @click="addTeamClicked">
                  Add team
                </b-button>
              </b-card-text>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="teamsTable.currentPage"
              :items="teamsTable.items" :fields="teamsTable.fields" :sort-by.sync="teamsTable.sortBy"
              :sort-desc.sync="teamsTable.sortDesc" :sort-direction="teamsTable.sortDirection"
              :filter="teamsTable.filter" :filter-included-fields="teamsTable.filterOn"
              @row-clicked="teamsTableRowClicked">
              <template #cell(delete)="data">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
                  @click="openDeleteTeamConfirm(data.item.id)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="teamsTable.currentPage" :total-rows="teamsTable.totalRows" first-number
                  last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleTeamsTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-modal>
      <b-modal ref="modal-team-submit" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
        centered :title="teamID ? 'Update Team' : 'Add Team'" :no-close-on-backdrop="true" @ok="handleTeamFormSubmit"
        @hidden="resetTeamFormModal">
        <b-form @submit.prevent>
          <b-row>
            <!-- Name -->
            <b-col cols="12">
              <b-form-group label="Name" label-for="team-name">
                <b-form-input id="team-name" v-model="teamName" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal ref="show-contracts-modal" size="xl" title="Contracts" cancel-title="Close"
        cancel-variant="outline-secondary" :no-close-on-backdrop="true">
        <b-overlay :show="showContracts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Contracts
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-contracts" />
              </h4>
              <b-popover target="popover-contracts" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
              <b-card-text class="font-medium-5 mb-0">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block"
                  @click="addContractClicked">
                  Add contract
                </b-button>
              </b-card-text>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="contractsTable.currentPage"
              :items="contractsTable.items" :fields="contractsTable.fields" :sort-by.sync="contractsTable.sortBy"
              :sort-desc.sync="contractsTable.sortDesc" :sort-direction="contractsTable.sortDirection"
              :filter="contractsTable.filter" :filter-included-fields="contractsTable.filterOn"
              @row-clicked="contractsTableRowClicked">
              <template #cell(delete)="data">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
                  @click="openDeleteContractConfirm(data.item.id)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="contractsTable.currentPage" :total-rows="contractsTable.totalRows" first-number
                  last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleContractsTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-modal>
      <b-modal ref="modal-contract-submit" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
        centered :title="contractID ? 'Update Contract' : 'Add Contract'" :no-close-on-backdrop="true"
        @ok="handleContractFormSubmit" @hidden="resetContractFormModal">
        <b-form @submit.prevent>
          <b-row>
            <!-- Name -->
            <b-col cols="12">
              <b-form-group label="Name" label-for="contract-name">
                <b-form-input id="contract-name" v-model="contractName" />
              </b-form-group>
            </b-col>
            <!-- Percentage -->
            <b-col cols="12">
              <b-form-group label="Percentage" label-for="contract-percentage">
                <b-form-input id="contract-percentage" v-model="contractPercentage" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal ref="modal-employee-submit" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
        centered :title="employeeID ? 'Update Employee' : 'Add Employee'" :no-close-on-backdrop="true"
        @ok="handleEmployeeFormSubmit" @hidden="resetEmployeeFormModal">
        <b-form @submit.prevent>
          <b-row>
            <!-- First Name -->
            <b-col cols="12">
              <b-form-group label="First Name" label-for="employee-first-name">
                <b-form-input id="employee-first-name" v-model="employeeFirstName" />
              </b-form-group>
            </b-col>
            <!-- Last Name -->
            <b-col cols="12">
              <b-form-group label="Last Name" label-for="employee-last-name">
                <b-form-input id="employee-last-name" v-model="employeeLastName" />
              </b-form-group>
            </b-col>
            <!-- Hourly Rate -->
            <b-col cols="12">
              <b-form-group label="Hourly Rate" label-for="employee-hourly-rate">
                <b-form-input id="employee-hourly-rate" v-model="employeeHourlyRate" />
              </b-form-group>
            </b-col>
            <!-- User -->
            <b-col cols="12">
              <b-form-group label="User" label-for="employee-user">
                <v-select id="employee-user" v-model="selectedEmployeeUser" label="name" :options="users" />
              </b-form-group>
            </b-col>
            <!-- Team -->
            <b-col cols="12">
              <b-form-group label="Team" label-for="employee-team">
                <v-select id="employee-team" v-model="selectedEmployeeTeam" label="name" :options="teams" />
              </b-form-group>
            </b-col>
            <!-- Contract -->
            <b-col cols="12">
              <b-form-group label="Contract" label-for="employee-contract">
                <v-select id="employee-contract" v-model="selectedEmployeeContract" label="name" :options="contracts" />
              </b-form-group>
            </b-col>
            <!-- Is freelancer -->
            <b-col cols="12">
              <b-form-group label="Freelancer" label-for="is-freelancer">
                <b-form-checkbox id="is-freelancer" v-model="isFreelancer" label="freelancer">
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BBadge,
  BPagination,
  BButton,
  BButtonGroup,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BOverlay,
  BModal,
  BInputGroup,
  BForm,
  BFormText,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormCheckbox,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BPagination,
    BButton,
    BButtonGroup,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BModal,
    BInputGroup,
    BForm,
    BFormText,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormCheckbox,
    vSelect,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showUsers: false,
      showRoles: false,
      showEmployees: false,
      showTeams: false,
      showContracts: false,
      queryParamsUsers: {},
      queryParamsRoles: {},
      queryParamsEmployees: {},
      queryParamsTeams: {},
      queryParamsContracts: {},
      usersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'last_login',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'username', label: 'username', sortable: true },
          { key: 'full_name', label: 'full name', sortable: true },
          { key: 'email', label: 'email', sortable: true },
          { key: 'role', label: 'role', sortable: true },
          { key: 'vacation_days_per_year', label: 'vacations per year', sortable: true },
          { key: 'vacation_remainder', label: 'vacations remainder', sortable: true },
          { key: 'vacation_days_used', label: 'vacations used', sortable: true },
          { key: 'sick_days_per_year', label: 'sickdays per year', sortable: true },
          { key: 'sick_days_used', label: 'sickdays used', sortable: true },
          {
            key: 'last_login',
            label: 'Last login',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleString('de-DE', {
                  timeZone: 'Europe/Berlin',
                });
              }
              return '';
            },
          },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      rolesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      employeesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'full_name', label: 'full name', sortable: true },
          { key: 'user', label: 'username', sortable: true },
          {
            key: 'hourly_rate',
            label: 'hourly rate',
            sortable: true,
            formatter: val => `€ ${this.$formatNumber(parseFloat(val))}`,
          },
          { key: 'team', label: 'team', sortable: true },
          { key: 'contract', label: 'contract', sortable: true },
          { key: 'is_freelancer', label: 'freelancer', sortable: true },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      teamsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      contractsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'percentage',
            label: 'percentage',
            sortable: true,
            formatter: val => `${this.$formatNumber(parseFloat(val))}`,
          },
          { key: 'delete', label: '' },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      userID: '',
      email: '',
      username: '',
      role: '',
      selectedRole: '',
      vacationPerYear: 0,
      sickdayPerYear: 0,
      password: '',
      rePassword: '',
      roles: [],
      roleID: '',
      roleName: '',
      accessPages: [],
      accessPagesSelected: [],
      employeeID: '',
      employeeFirstName: '',
      employeeLastName: '',
      employeeHourlyRate: 0.0,
      employeeUser: '',
      employeeTeam: '',
      employeeContract: '',
      selectedEmployeeUser: '',
      selectedEmployeeTeam: '',
      selectedEmployeeContract: '',
      isFreelancer: false,
      users: [],
      teams: [],
      contracts: [],
      teamID: '',
      teamName: '',
      contractID: '',
      contractName: '',
      contractPercentage: 0.0,
    }
  },
  computed: {
    validation() {
      return this.password.length > 4 && this.password.length < 13;
    },
  },
  async created() {
    try {
      await this.getAllUsers();
      await this.getUsers();
      await this.getRoles();
      await this.getAccessPages();
      await this.getEmployees();
      await this.getTeams();
      await this.getContracts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllUsers() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-users/`, {});
        this.users = response.data.map(item => ({ name: item.username, id: item.id }));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getUsers() {
      this.showUsers = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/users/`, this.queryParamsUsers);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.usersTable.items = results;
          this.usersTable.totalRows = response.data.count * 2;
        }
        else {
          this.usersTable.items = [];
          this.usersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showUsers = false;
      }
    },
    async getRoles() {
      this.showRoles = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/roles/`, {});
        const results = response.data.results;
        this.roles = results.map(item => ({ name: item.name, id: item.id }));
        if (Array.isArray(results) && results.length > 0) {
          this.rolesTable.items = results;
          this.rolesTable.totalRows = response.data.count * 2;
        }
        else {
          this.rolesTable.items = [];
          this.rolesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showRoles = false;
      }
    },
    async getAccessPages() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-page-accesses/`, {});
        this.accessPages = response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getEmployees() {
      this.showEmployees = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/employee-profiles/`, this.queryParamsEmployees);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.employeesTable.items = results;
          this.employeesTable.totalRows = response.data.count * 2;
        }
        else {
          this.employeesTable.items = [];
          this.employeesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showEmployees = false;
      }
    },
    async getTeams() {
      this.showTeams = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/teams/`, {});
        const results = response.data.results;
        this.teams = results.map(item => ({ name: item.name, id: item.id }));
        if (Array.isArray(results) && results.length > 0) {
          this.teamsTable.items = results;
          this.teamsTable.totalRows = response.data.count * 2;
        }
        else {
          this.teamsTable.items = [];
          this.teamsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showTeams = false;
      }
    },
    async getContracts() {
      this.showContracts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/contracts/`, {});
        const results = response.data.results;
        this.contracts = results.map(item => ({ name: item.name, id: item.id }));
        if (Array.isArray(results) && results.length > 0) {
          this.contractsTable.items = results;
          this.contractsTable.totalRows = response.data.count * 2;
        }
        else {
          this.contractsTable.items = [];
          this.contractsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showContracts = false;
      }
    },
    async handleUsersTablePageChange(value) {
      this.queryParamsUsers.page = value;
      await this.getUsers();
    },
    async handleRolesTablePageChange(value) {
      this.queryParamsRoles.page = value;
      await this.getRoles();
    },
    async handleEmployeesTablePageChange(value) {
      this.queryParamsEmployees.page = value;
      await this.getEmployees();
    },
    async handleTeamsTablePageChange(value) {
      this.queryParamsTeams.page = value;
      await this.getTeams();
    },
    async handleContractsTablePageChange(value) {
      this.queryParamsContracts.page = value;
      await this.getContracts();
    },
    openRolesTable() {
      this.$refs['show-roles-modal'].show();
    },
    openTeamsTable() {
      this.$refs['show-teams-modal'].show();
    },
    openContractsTable() {
      this.$refs['show-contracts-modal'].show();
    },
    addUserClicked() {
      this.$refs['modal-user-submit'].show();
    },
    addRoleClicked() {
      this.$refs['modal-role-submit'].show();
    },
    addEmployeeClicked() {
      this.$refs['modal-employee-submit'].show();
    },
    addTeamClicked() {
      this.$refs['modal-team-submit'].show();
    },
    addContractClicked() {
      this.$refs['modal-contract-submit'].show();
    },
    usersTableRowClicked(row) {
      this.userID = row.id;
      this.email = row.email;
      this.username = row.username;
      this.vacationPerYear = row.vacation_days_per_year;
      this.sickdayPerYear = row.sick_days_per_year;
      this.selectedRole = row.role_name;
      this.role = row.role;
      this.password = row.password;
      this.$refs['modal-user-submit'].show();
    },
    rolesTableRowClicked(row) {
      this.roleID = row.id;
      this.roleName = row.name;
      this.accessPagesSelected = row.page_accesses_names;
      this.$refs['modal-role-submit'].show();
    },
    employeesTableRowClicked(row) {
      this.employeeID = row.id;
      this.employeeFirstName = row.first_name;
      this.employeeLastName = row.last_name;
      this.employeeHourlyRate = row.hourly_rate;
      this.employeeUser = row.user;
      this.employeeTeam = row.team;
      this.employeeContract = row.contract;
      this.selectedEmployeeUser = row.user_name;
      this.selectedEmployeeTeam = row.team_name;
      this.selectedEmployeeContract = row.contract_name;
      this.isFreelancer = row.is_freelancer;
      this.$refs['modal-employee-submit'].show();
    },
    teamsTableRowClicked(row) {
      this.teamID = row.id;
      this.teamName = row.name;
      this.$refs['modal-team-submit'].show();
    },
    contractsTableRowClicked(row) {
      this.contractID = row.id;
      this.contractName = row.name;
      this.contractPercentage = row.percentage;
      this.$refs['modal-contract-submit'].show();
    },
    resetUserFormModal() {
      this.userID = '';
      this.email = '';
      this.username = '';
      this.selectedRole = '';
      this.role = '';
      this.vacationPerYear = 0;
      this.sickdayPerYear = 0;
      this.password = '';
      this.rePassword = '';
    },
    resetRoleFormModal() {
      this.roleID = '';
      this.roleName = '';
      this.accessPagesSelected = [];
    },
    resetEmployeeFormModal() {
      this.employeeID = '';
      this.employeeFirstName = '';
      this.employeeLastName = '';
      this.employeeHourlyRate = 0.0;
      this.employeeUser = '';
      this.employeeTeam = '';
      this.employeeContract = '';
      this.selectedEmployeeUser = '';
      this.selectedEmployeeTeam = '';
      this.selectedEmployeeContract = '';
      this.isFreelancer = false;
    },
    resetTeamFormModal() {
      this.teamID = '';
      this.teamName = '';
    },
    resetContractFormModal() {
      this.contractID = '';
      this.contractName = '';
      this.contractPercentage = 0.0;
    },
    async handleUserFormSubmit() {
      const params = {
        email: this.email,
        username: this.username,
        vacation_days_per_year: this.vacationPerYear,
        sick_days_per_year: this.sickdayPerYear,
        password: this.password,
      };

      if (typeof this.selectedRole === 'string') {
        params.role = this.role;
      } else {
        params.role = this.selectedRole.id;
      }

      // Update
      if (this.userID && this.email !== '' && this.username !== '' && this.role !== '' && this.password !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/users/${this.userID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'User has been updated.');
            await this.getUsers();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      }
      // Create
      else if (this.email !== '' && this.username !== '' && this.selectedRole.id !== '' && this.password !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/users/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'User has been created.');
            await this.getUsers();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!'); }
    },
    async handleRoleFormSubmit() {
      // Update
      const params = {
        name: this.roleName,
        page_accesses: this.accessPagesSelected.map(item => item.id),
      };

      // Update
      if (this.roleID && this.roleName !== '' && this.accessPagesSelected.length !== 0) {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/roles/${this.roleID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Role has been updated.');
            await this.getRoles();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      }
      // Create
      else if (this.roleName !== '' && this.accessPagesSelected.length !== 0) {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/roles/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Role has been created.');
            await this.getRoles();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!'); }
    },
    async handleEmployeeFormSubmit() {
      const params = {
        first_name: this.employeeFirstName,
        last_name: this.employeeLastName,
        hourly_rate: this.employeeHourlyRate,
        is_freelancer: this.isFreelancer,
      };

      if (typeof this.selectedEmployeeUser === 'string') {
        params.user = this.employeeUser;
      } else if (this.selectedEmployeeUser != null) {
        params.user = this.selectedEmployeeUser.id;
      } else {
        params.user = this.selectedEmployeeUser;
      }

      if (typeof this.selectedEmployeeTeam === 'string') {
        params.team = this.employeeTeam;
      } else if (this.selectedEmployeeTeam != null) {
        params.team = this.selectedEmployeeTeam.id;
      } else {
        params.team = this.selectedEmployeeTeam;
      }

      if (typeof this.selectedEmployeeContract === 'string') {
        params.contract = this.employeeContract;
      } else if (this.selectedEmployeeContract != null) {
        params.contract = this.selectedEmployeeContract.id;
      } else {
        params.contract = this.selectedEmployeeContract;
      }

      // Update
      if (this.employeeID && this.employeeFirstName !== '' && this.employeeLastName !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/employee-profiles/${this.employeeID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Employee has been updated.');
            await this.getEmployees();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      }
      // Create
      else if (this.employeeFirstName !== '' && this.employeeLastName !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/employee-profiles/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Employee has been created.');
            await this.getEmployees();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!'); }
    },
    async handleTeamFormSubmit() {
      const params = {
        name: this.teamName,
      };

      // Update
      if (this.teamID && this.teamName !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/teams/${this.teamID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Team has been updated.');
            await this.getTeams();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      }
      // Create
      else if (this.teamName !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/teams/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Team has been created.');
            await this.getTeams();
          })
          .catch(error => {
            console.log(error.response)
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!'); }
    },
    async handleContractFormSubmit() {
      const params = {
        name: this.contractName,
        percentage: this.contractPercentage,
      };

      // Update
      if (this.contractID && this.contractName !== '') {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/contracts/${this.contractID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Contract has been updated.');
            await this.getContracts();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      }
      // Create
      else if (this.contractName !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/contracts/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Contract has been created.');
            await this.getContracts();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!'); }
    },
    openDeleteUserConfirm(id) {
      this.userID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/users/${this.userID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'User has been deleted');
              await this.getUsers();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.userID = '';
      })
    },
    openDeleteRoleConfirm(id) {
      this.roleID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/roles/${this.roleID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'Role has been deleted');
              await this.getRoles();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.roleID = '';
      })
    },
    openDeleteEmployeeConfirm(id) {
      this.employeeID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .patch(`${process.env.VUE_APP_ROOT_API}/employee-profiles/${this.employeeID}/`,
              {
                is_active: false,
              },
              {
                headers: {
                  Authorization: `JWT ${this.$store.state.jwt}`,
                  'Content-Type': 'application/json',
                },
              })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'Employee has been deactivated');
              await this.getEmployees();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.employeeID = '';
      })
    },
    openDeleteTeamConfirm(id) {
      this.teamID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/teams/${this.teamID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'Team has been deleted');
              await this.getTeams();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.teamID = '';
      })
    },
    openDeleteContractConfirm(id) {
      this.contractID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios
            .delete(`${process.env.VUE_APP_ROOT_API}/contracts/${this.contractID}/`, {
              headers: {
                Authorization: `JWT ${this.$store.state.jwt}`,
                'Content-Type': 'application/json',
              },
            })
            .then(async response => {
              console.log(response);
              this.showToast('info', 'Contract has been deleted');
              await this.getContracts();
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$router.push({ name: 'login' });
              }
            })
        }
        this.contractID = '';
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>
